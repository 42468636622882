import { useQueryClient } from '@tanstack/react-query';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { classNames } from 'primereact/utils';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { ReadyState } from 'react-use-websocket';

import InfoIconPrimary from '../../assets/icons/InfoIconPrimary';
import EmptyProductsImg from '../../assets/images/empty-products.svg';
import CustomSpinner from '../../components/custom-spinner/CustomSpinner';
import DownloadJsonFileButton from '../../components/download-pcf/DownloadJsonFileButton';
import DownloadPdfFileButton from '../../components/download-pcf/DownloadPdfFileButton';
import EmptyScreen from '../../components/empty-screen/EmptyScreen';
import RequestStatus from '../../components/request-status/RequestStatus';
import WebsocketContext, {
  IWebsocketMessage,
  WebsocketEventType,
} from '../../contexts/websocket/WebsocketContext';
import { ProductsParams, useListProducts } from '../../hooks/api/products';
import { useCreatePcfRequest } from '../../hooks/api/request-pcf';
import useDialogContext from '../../hooks/dialog/useDialogContext';
import useNotificationContext from '../../hooks/notification/useNotificationContext';
import ProductRegion from '../../shared/components/product-region/ProductRegion';
import { PCFBiogenicEmissionsMode } from '../../shared/enums/pcf';
import { PCFRequestStatus } from '../../shared/enums/pcf-request';
import { ToastSeverity } from '../../shared/enums/toast-severity';
import { requestRegionSortFunction } from '../../shared/helpers/products-sort-region-function';
import { IProduct } from '../../shared/interfaces/IProduct';
import ProductId from './components/ProductId';
import ProductName from './components/ProductName';
import ProductPcfValue from './components/ProductPcfValue';
import ProductsHeader from './components/ProductsHeader';
import { ProductFilters } from './interfaces/product-item';
import styles from './ProductsPage.module.css';
import { initialProductsListRequest } from './toast/request-toast';

const ProductsPage = () => {
  // Search params (filters) from the URL
  const [searchParams, setSearchParams] = useSearchParams();

  const [biogenicEmissionsMode, setBiogenicEmissionsMode] = useState<PCFBiogenicEmissionsMode>(
    PCFBiogenicEmissionsMode.Include,
  );

  // Parameters used for filtering when fetching products
  const [productFilters, setProductFilters] = useState<ProductFilters>({
    statusFilter: searchParams.get('status')?.split(',') || [],
    regionsFilter: searchParams.get('region')?.split(',') || [],
    searchStr: searchParams.get('search') || '',
  });

  // For requesting pcf for products with region, to disable the Request Pcf button for the requested product
  // It is a map of product_id to boolean, where boolean is true if the product is requested
  // So that you can request pcf for multiple products at the same time (USING BUTTONS IN EACH ROW)
  const [selectedProductsToRequestPcf, setSelectedProductsToRequestPcf] = useState<
    Record<string, boolean>
  >({});

  // For bulk pcf requesting, to apply Optimistic UI strategy, to show those products as "Pending" in the table
  // Without them actually being "Pending" in the database
  // Those products will be declared in the BulkRequestPcfsDialog
  const [productsToShowPendingDuringBulk, setProductsToShowPendingDuringBulk] = useState<
    IProduct[]
  >([]);

  // For exporting selected products to csv

  const { notify } = useNotificationContext();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { openDialog } = useDialogContext();

  const { lastJsonMessage, readyState } = useContext(WebsocketContext);

  const { isLoading: loading, mutate: createInitialProductsListRequest } = useCreatePcfRequest({
    onSuccess: () => {
      const notifyData = initialProductsListRequest(
        ToastSeverity.INFO,
        t('DetailsContent.requestSubmitted'),
        t('DetailsContent.yourRequestWillBeReviewedAsSoonAsPossible'),
        t('DetailsContent.checkBackPeriodicallyToSeeIfYourProductsAreAdded'),
      );
      notify(notifyData);
    },
    onError: () => {
      const notifyData = initialProductsListRequest(
        ToastSeverity.ERROR,
        t('DetailsContent.failedToSubmitRequest'),
        t('DetailsContent.somethingWentWrong'),
        t('DetailsContent.pleaseTryAgainLater'),
      );
      notify(notifyData);
    },
  });

  /* eslint-disable camelcase */
  const { mutate: createPcfRequest } = useCreatePcfRequest({
    onMutate: (variables) => {
      // Make the RequestPcf button disabled for the requested product
      setSelectedProductsToRequestPcf((prev) => {
        return { ...prev, [variables.product_id as number]: true };
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['listProducts'] });
      queryClient.invalidateQueries({ queryKey: ['requests'] });
    },
    onError: () => {
      notify({
        severity: ToastSeverity.ERROR,
        summary: t('DetailsContent.pcfRequestFailed'),
        detail: t('DetailsContent.pleaseTryAgainLater'),
        life: 5000,
      });
    },
    onSettled: (data, error, variables) => {
      setSelectedProductsToRequestPcf((prev) => {
        // After half a second, set the product to false to enable the button again
        // Make the RequestPcf button enabled for the requested product at the end
        return { ...prev, [variables.product_id as number]: false };
      });
    },
  });
  /* eslint-enable camelcase */

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Prepare the filter parameters for fetching products, utilizing memoization
  const filterParams = useMemo(() => {
    const paramsValue: ProductsParams = {};
    if (productFilters.statusFilter.length) {
      paramsValue['status'] = productFilters.statusFilter.join(',');
    }

    if (productFilters.regionsFilter.length) {
      paramsValue['region'] = productFilters.regionsFilter.join(',');
    }

    if (productFilters.searchStr) {
      paramsValue['search'] = productFilters.searchStr.trim();
    }

    return paramsValue;
  }, [productFilters]);

  // RQ Query to get products
  const {
    data: fetchedProducts,
    isFetching,
    isError,
    isSuccess,
    refetch: refetchProducts,
  } = useListProducts(filterParams);

  // State to store the fetched products (initially start with fetchedProducts, use cache if needed)
  const [products, setProducts] = useState<IProduct[]>(fetchedProducts || []);

  useEffect(() => {
    if (fetchedProducts && isSuccess) {
      setProducts(fetchedProducts);
    }
  }, [fetchedProducts, isSuccess]);

  // Listen to websocket messages
  useEffect(() => {
    if (readyState === ReadyState.OPEN && lastJsonMessage) {
      const websocketMessage = lastJsonMessage as IWebsocketMessage;

      // If the websocket message is for a single request_pcf event (by clicking the button in the row)
      if (websocketMessage.eventType === WebsocketEventType.RequestPCF) {
        refetchProducts();

        if (websocketMessage.success === true) {
          notify({
            severity: ToastSeverity.SUCCESS,
            summary: t('toastMessages.pcfRequest.successSummary', {
              productName: websocketMessage.productName,
            }),
            detail: t('toastMessages.pcfRequest.successDetail'),
            life: 5000,
          });
        } else if (websocketMessage.success === false) {
          notify({
            severity: ToastSeverity.ERROR,
            summary: t('toastMessages.pcfRequest.errorSummary', {
              productName: websocketMessage.productName,
            }),
            detail: t('toastMessages.pcfRequest.errorDetail'),
            life: 5000,
          });
        }
      }

      // If the websocket message is for a single product in the bulk_request_pcf event (by the BulkRequestPcfsDialog) (EDC flow)
      // Then update the state of this product in the table depending on the response
      if (websocketMessage.eventType === WebsocketEventType.RequestBulkPcfSingleCompleted) {
        const { success, product } = websocketMessage;

        if (success && product) {
          setProducts((prev) => {
            return prev.map((p) => {
              if (p.product_id === product.product_id) {
                return product;
              }
              return p;
            });
          });
        }
      }

      // If the websocket message is for the summary of the bulk_request_pcf event (by the BulkRequestPcfsDialog) (EDC flow)
      // Then show the summary toast an refetch the products to get the latest state of the products
      if (websocketMessage.eventType === WebsocketEventType.RequestBulkPcfSummary) {
        refetchProducts();

        // Depending on the success and error count, show toast messages
        const { successCount, failureCount } = websocketMessage;

        // Show success and error toasts based on the response
        if (successCount) {
          notify({
            severity: ToastSeverity.SUCCESS,
            detail: t('toastMessages.bulkRequestPcfs.states.success.detail', { successCount }),
          });
        }

        if (failureCount) {
          notify({
            severity: ToastSeverity.ERROR,
            detail: t('toastMessages.bulkRequestPcfs.states.failure.detail', { failureCount }),
          });
        }
      }
    }
  }, [readyState, lastJsonMessage]);

  // Listen to productsToShowPendingDuringBulk changes
  // So that during their bulk-request process, we can show those selected products as "Pending" in the table
  useEffect(() => {
    if (productsToShowPendingDuringBulk.length) {
      setProducts((prev) => {
        return prev.map((product) => {
          const optimisticallyUpdatedProduct = productsToShowPendingDuringBulk.find((p) => {
            return p.product_id === product.product_id;
          });

          return optimisticallyUpdatedProduct ? optimisticallyUpdatedProduct : product;
        });
      });
    }
  }, [productsToShowPendingDuringBulk]);

  // Listen to URL string parameter changes, and update the filter values
  useEffect(() => {
    setProductFilters({
      statusFilter: searchParams.get('status')?.split(',') || [],
      regionsFilter: searchParams.get('region')?.split(',') || [],
      searchStr: searchParams.get('search')?.trim() || '',
    });
  }, [searchParams]);

  // Function for updating the filter values in the URL
  const updateProductFilters = (newFilters: ProductFilters) => {
    let newSearchParams;

    if (newFilters.statusFilter.length) {
      newSearchParams = { status: newFilters.statusFilter.join(',') };
    }

    if (newFilters.regionsFilter.length) {
      newSearchParams = { ...newSearchParams, region: newFilters.regionsFilter.join(',') };
    }

    if (newFilters.searchStr) {
      newSearchParams = { ...newSearchParams, search: newFilters.searchStr };
    }

    // Update URL parameters
    setSearchParams(newSearchParams);
  };

  const handleTicketSubmission = () => {
    createInitialProductsListRequest({
      // eslint-disable-next-line camelcase
      request_type: 'initial_product_list',
    });
  };

  const onRequestPCFButtonClick = useCallback((product: IProduct) => {
    // If product has no region, show the Dialog
    if (!product.region) {
      openDialog('request-pcf-dialog', { product });
    } else {
      // If product already has a region, fetch PCF data without showing any Dialog and refresh products

      /* eslint-disable camelcase */
      createPcfRequest({
        product_id: product.product_id,
        region: product.region,
        request_type: 'pcf',
        file_type: ['pdf', 'json'],
      });
      /* eslint-enable camelcase */
    }
  }, []);

  if (isError) {
    return <p className='text-red-500'>{t('productsPage.failedToGetProducts')}</p>;
  }

  const productNameTemplate = (product: IProduct) => {
    // If the product is completed, prepare href for the product link
    const href =
      product.status === PCFRequestStatus.Complete ? `/products/${product.product_id}` : '';

    return (
      <ProductName
        productId={product.product_id}
        productName={product.product_name}
        ownProductName={product.own_product_name}
        sustainableAlternativeAvailable={product.sustainable_alternative_available}
        href={href}
      />
    );
  };

  const productIdTemplate = (product: IProduct) => {
    return <ProductId productId={product.product_cid} ownProductId={product.own_product_id} />;
  };

  const regionTemplate = (product: IProduct) => {
    return <ProductRegion product={product} />;
  };

  const productStatusTemplate = (product: IProduct) => {
    return <RequestStatus status={product.status} />;
  };

  const pcfsTemplate = (product: IProduct) => {
    return (
      <div className='flex'>
        {product.request_id && product.status === PCFRequestStatus.Complete && (
          <>
            <DownloadPdfFileButton requestId={product.request_id} />
            <DownloadJsonFileButton
              productName={product.product_name}
              requestId={product.request_id}
            />
          </>
        )}
      </div>
    );
  };

  const requestPcfTemplate = (product: IProduct) => {
    // If the product is already requested, don't show the button
    if (product.request_id && !selectedProductsToRequestPcf[product.product_id]) return;

    return (
      <Button
        className='w-full'
        label={t('productsPage.requestPcf')}
        disabled={selectedProductsToRequestPcf[product.product_id]}
        loading={selectedProductsToRequestPcf[product.product_id]}
        onClick={() => {
          return onRequestPCFButtonClick(product);
        }}
      />
    );
  };

  const pcfValueTemplate = (product: IProduct) => {
    return <ProductPcfValue product={product} biogenicEmissionsMode={biogenicEmissionsMode} />;
  };

  const productType = 'BASF';

  // If fetched products are empty, show the empty screen
  if (
    !fetchedProducts?.length &&
    products?.length === 0 &&
    Object.keys(filterParams).length === 0 &&
    !isFetching
  ) {
    return (
      <EmptyScreen imageSrc={EmptyProductsImg}>
        <h4>{t('emptyProductsPage.heading')}</h4>
        <p>{t('emptyProductsPage.description1')}</p>
        <div className='flex flex-wrap align-items-center'>
          <p className='m-0 flex flex-wrap align-items-center'>
            {t('emptyProductsPage.description2')}
            <Button
              label={
                loading
                  ? t('emptyProductsPage.submittingTicket')
                  : t('emptyProductsPage.submitTicket')
              }
              onClick={handleTicketSubmission}
              className={classNames('text-primary underline p-0 ml-1', styles['submit-ticket'])}
              disabled={loading}
              text
            />
          </p>
          {loading && <CustomSpinner strokeWidth={8} className='w-1rem ml-2' />}
        </div>
      </EmptyScreen>
    );
  }

  return (
    <div className={classNames('px-3 py-5 sm:p-5 flex flex-column', styles['products-page'])}>
      <h1 className='text-2xl md:text-4xl'>
        <Trans i18nKey='productsPage.heading' values={{ productType }} />
      </h1>

      <div className='flex flex-column gap-4'>
        <DataTable
          loading={isFetching}
          value={products}
          scrollable
          header={
            <ProductsHeader
              productFilters={productFilters}
              updateProductFilters={updateProductFilters}
              biogenicEmissionsMode={biogenicEmissionsMode}
              setBiogenicEmissionsMode={setBiogenicEmissionsMode}
              productsToShowPendingDuringBulk={productsToShowPendingDuringBulk}
              setProductsToShowPendingDuringBulk={setProductsToShowPendingDuringBulk}
            />
          }
          pt={{
            wrapper: {
              className: styles['table-wrapper'],
            },
          }}
        >
          <Column
            field='product_name'
            sortable
            header={t('productsPage.productName')}
            style={{ minWidth: '8rem', maxWidth: '16rem' }}
            frozen
            body={productNameTemplate}
          />
          <Column
            field='product_cid'
            sortable
            header={t('productsPage.productId')}
            style={{ minWidth: '8rem', maxWidth: '12rem' }}
            body={productIdTemplate}
          />
          <Column
            field='region'
            sortable
            sortFunction={requestRegionSortFunction}
            header={t('productsPage.region')}
            style={{ minWidth: '10rem' }}
            body={regionTemplate}
          />
          <Column
            field={
              biogenicEmissionsMode === PCFBiogenicEmissionsMode.Include
                ? 'pcf_including'
                : 'pcf_excluding'
            }
            sortable
            header={t('productsPage.pcfValue')}
            body={pcfValueTemplate}
            style={{ minWidth: '8rem' }}
          />
          <Column
            header={
              <div className='flex gap-2'>
                {t('productsPage.pcfs')}
                <Button
                  className='p-0 flex justify-content-start info-icon cursor-pointer'
                  link
                  icon={InfoIconPrimary}
                  onClick={() => {
                    openDialog('pcf-info-dialog');
                  }}
                />
              </div>
            }
            body={pcfsTemplate}
            style={{ minWidth: '6rem' }}
          />

          <Column
            header={t('productsPage.status')}
            style={{ minWidth: '6rem' }}
            body={productStatusTemplate}
          />
          <Column body={requestPcfTemplate} className='w-12rem' />
        </DataTable>
        <Button
          className='mr-auto mb-4 px-3'
          label={t('productsPage.requestAdditionalProduct')}
          onClick={() => {
            openDialog('request-additional-product-dialog');
          }}
        />
      </div>
    </div>
  );
};

export default ProductsPage;
